import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

import Modal from "./bootstrap/modal"

export default function TermModal(props) {
  const { onClose } = props

  return (
    <Modal
      title={"Terms of Use"}
      onClose={onClose}
      show
      footer={
        <Row>
          <Col xs="auto" className="ml-auto"></Col>
          <Col xs="auto">
            <Button variant="darkblue" type="button" onClick={() => onClose()}>
              {"Close"}
            </Button>
          </Col>
        </Row>
      }
    >
      <Container fluid className="terms">
        <Row>
          <Col>
          <h2>
              <strong>Langston Free Public Wi-Fi Terms and Conditions of Use</strong>
            </h2>
            <p>
              Welcome to Langston's free Wi-Fi service for use by the public (‘the
              Service’). All users are required to log-in individually as a
              separate user.
            </p>
            <p>
              Access to the Service is subject to the following Terms and
              Conditions. Langston may modify these Terms and Conditions at any time,
              without notification to you.You should ensure you review these
              Terms and Conditions each time you access the Service.
            </p>
            <p>
              <br />
              By selecting “Accept” and accessing and using the Service, you:
            </p>
            <ul>
              <li>
                <strong>
                  acknowledge that you are at least 18 years of age or have
                  obtained consent from your parent or legal guardian to use the
                  Service
                </strong>
                ;<br />{" "}
              </li>
              <li>
                agree to be bound by these terms and conditions.
                <br />
                <br />
                <strong>
                  If you do not agree with these terms and conditions you must
                  not access or use Langston’s Public Wi-Fi service.
                </strong>
                <br />
              </li>
              <li>
                assume all responsibility and risk arising out of or in
                connection with use of the Service.
              </li>
            </ul>
            <p>
              VostroNet (Australia) Pty Ltd (“the Provider”) provides access to
              the internet through the Service on behalf of Langston. In these Terms
              and Conditions, all references to “Langston” will be taken to include
              the Provider.
            </p>
            <p></p>
            <h2>
              <strong>Terms and Conditions</strong>
            </h2>
            <ol>
              <li>
                The Service is available to your wireless-enabled device/s only
                when it is within the range of Langston’s wireless Local Access
                Network (“LAN”). You are responsible for providing your own
                wireless-enabled device capable of accessing the Service with
                its own power source.
              </li>
              <li>
                The Services may be withdrawn or changed, or your access blocked
                or restricted at any time for any reason and without notice.
                Sessions may be terminated without notice at the discretion of
                Langston.
              </li>
              <li>
                Session time and download limits may be applied to your use of
                the Service. These limits may be determined by Langston in its
                absolute discretion without notice to you.
              </li>
              <li>Your use of the Service must:</li>
              <li>be in accordance with all applicable laws;</li>
              <li>
                not infringe the intellectual property rights – including
                copyright – of any person. You are responsible for all consents,
                royalties and fees related to third party materials accessed
                through the Service;
              </li>
              <li>
                not interfere with the normal operation of the Service or
                equipment used in providing the Service;
              </li>
              <li>
                not be for any commercial or business purpose. You must not sell
                or re-sell the Service;
              </li>
              <li>
                not expose any minor to material that is unsuitable for minors;
              </li>
              <li>
                not cause Langston to breach any law or incur liability to a third
                party, or cause damage to Langston’s business or reputation or the
                public;
              </li>
              <li>
                not be for any criminal,unlawful, defamatory, threatening,
                sexually explicit or pornographic, violent, abusive, harmful, or
                obscene purpose , and must not otherwise be a nuisance, spread
                or incite hatred, or breach privacy.
              </li>
              <li>
                The Service is provided on an “as is” basis and Langston does not
                warrant that the Service will be uninterrupted, fault-free,
                secure, accessible at all times or fit for any particular
                purpose. Langston has no obligation to restore faults in the Service,
                or to provide power, advice or assistance. The Service is not
                suitable for supporting any applications or use which require
                continuous, fault-free network connectivity or uninterrupted
                data traffic flow.
              </li>
              <li>
                You are responsible for the security, privacy and backing up of
                your software and applications, and any information you transmit
                using the Service.
              </li>
            </ol>
            <p></p>
            <h2>
              <strong>Langston Liability</strong>
            </h2>
            <ol>
              <li>
                Langston does not warrant or represent that any of the data, content
                or information you access through the Service is accurate,
                complete, secure or fit for any particular purpose. Langston does not
                control the materials and content you access through the Service
                and accordingly disclaims all liability in relation to such
                materials and content.
              </li>
              <li>
                Your use of the Service is entirely at your own risk.
                Accordingly, to the extent permitted by law, Langston excludes:
              </li>
              <li>
                all liability to you for any negligent acts or omissions by Langston,
                its employees, agents and contractors arising out of or in
                connection with the Service;
              </li>
              <li>
                all liability to you for any loss of profits, loss of revenue,
                loss of data, loss of any contract value, loss of anticipated
                profit, damages for lost opportunity or any other consequential
                or indirect loss arising from or in connection with your use of
                the Service;
              </li>
              <li>
                any conditions, warranties and terms implied by statute, common
                law or custom.
              </li>
              <li>
                To the extent permitted by law, Langston’s maximum liability to you
                for breach of an express provision of these Terms and Conditions
                is limited to supplying the Service again.
              </li>
              <li>
                You indemnify Langston from and against any losses, costs or
                liability Langston may suffer or incur as a result of:
              </li>
              <li>
                your use of the Service, including any material, data or
                information that you access, make available or receive using the
                Service; or
              </li>
              <li>
                any breach by you of any of these Terms and Conditions,
                including use of the Service by you (or permitted by you) which
                involves offensive, illegal or defamatory material or activities
                which constitute copyright infringement.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
